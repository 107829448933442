import React, { useEffect, useState } from "react";
import { commerce } from "../../lib/commerce";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

const Searchbar = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  const fetchProducts = async (value) => {
    await commerce.products
      .list({
        limit: 20,
        query: value,
      })
      .then((products) => {
        //If the api returns no products, set the products to an array with a message
        if (products.data === undefined) {
          setProducts([{ name: "Engar niðurstöður" }]); //TODO: Display this message in the autocomplete
        } else {
          setProducts(products.data);
        }
      })
      .catch((error) => {
        console.log("There was an error fetching the products", error);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="search-bar">
      <Autocomplete
        id="outlined-basic"
        options={products.map((product) => product.name)}
        // sx={{ width: 300 }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            navigate(`/voruleit?q=${e.target.value}`);
          }
        }}
        autoComplete={true}
        onChange={(e, value) => {
          if (value === null) {
            return;
          }
          navigate(`/voruleit?q=${value}`);
        }} // handle option click
        onInputChange={(e, value) => {
          if (value === "" || value === null) {
            return;
          } //make sure the product list is not empty
          fetchProducts(value);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Leita" variant="outlined" />
        )}
        freeSolo
        fullWidth
        variant="outlined"
        size="small"
      />
    </div>
  );
};

export default Searchbar;
