import React from "react";
import "../MainContentHome/style.css";
import { Link } from "react-router-dom";
import noBG from "../../resources/no-image.webp";

const Category = ({ category }) => (
  <Link id={category.id} to={`category-view/${category.id}`}>
    <div
      className="lowerOnHover"
      id="Lower1"
      style={{
        backgroundImage: `url(${
          category.assets.length > 0 ? category.assets[0].url : noBG
        })`,
      }}
    >
      <h2 className="lowerXtitles">{category.name}</h2>
      {/* <img src={category.assets[0].url} alt="" /> */}
    </div>
  </Link>
);

export default Category;
