import React from "react";
import "./style.css"
import InstaLogo from "../../resources/instagram-32.png"
import FacebookLogo from "../../resources/facebook-3-32.png"

const Contact = () => (
        
    <div className="contactContainer">
        <h1>Hafðu samband!</h1>
        <h2>Endilega hafðu samband við okkur eins og þér hentar</h2>
        <h2>Við svörum eins fljótt og hægt er</h2>
        <div className="contactInfo">
            <a href="tel:5303500" style={{"textDecoration":"none", "color":"inherit"}}>Sími: 530-3500</a>
            <br />
            <a href="mailto: samasem@samasem.is" style={{"textDecoration":"none", "color":"inherit"}}> Netfang: samasem@samasem.is</a>
            <br />
            <div className="contactFBIN">
                <a href="https://www.facebook.com/blomaheildsalan">
                    <img className="fb" src={FacebookLogo} alt="FaceBook Logo"/>
                </a>
                <a href="https://www.instagram.com/blomaheildsalan/?hl=en">
                    <img className="in" src={InstaLogo} alt="Instagram Logo"/>
                </a>
            </div>
        </div>
    </div>

    )

export default Contact;