import './style.css';
import React from 'react';
import noBG from '../../resources/No_picture_available.webp';

const Product = ({ product = () => { } }) => (
        <div className='productCard' style={{ backgroundImage: `url(${(product.image !== null) ? product.image.url : noBG})` }}>
                <img src="" alt="" />
                <div className='productName'>
                        {product.name}
                        <br />
                        {/* {product.price.formatted.slice(0,-3).replace(',','.')+'kr'} */}
                </div>
                {/* <img className='productCardImage' src={product.image.url} alt="" /> */}
        </div>

)

export default Product;