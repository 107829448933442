import React from "react";
import Category from "../Category/index";
import "./style.css";

const Categories = ({ categories }) => {
  return (
    <div className="categoriesList">
      {categories.map((category) => {
        return <Category category={category} />;
      })}
    </div>
  );
};

export default Categories;
