import React from "react";
import InstaLogo from "../../resources/instagram-32.png"
import FacebookLogo from "../../resources/facebook-3-32.png"
import "./style.css"

const MobileSocialMedia = () => (
    <div className="block-wrap">
        <div className="social-container">
            <a href="https://www.facebook.com/blomaheildsalan">
                <img className="fb" src={FacebookLogo} alt="FaceBook Logo"/>
            </a>
            <a href="https://www.instagram.com/blomaheildsalan/?hl=en">
                <img className="in" src={InstaLogo} alt="Instagram Logo"/>
            </a>
        </div>
            <ul className="MobilelistNavRight">
                <li>Grensásvegur 22</li>
                <li>108 Reykjavík</li>
                <li> <a href="tel:5303500" style={{"textDecoration":"none", "borderBottom":"none"}}>S: 5303500</a></li>
                <li> <a href="mailto: samasem@samasem.is" style={{"textDecoration":"none", "borderBottom":"none"}}>samasem@samasem.is</a></li>

            </ul>

    </div>
)

export default MobileSocialMedia;