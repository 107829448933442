import React from "react";
import "./style.css"
import { useNavigate } from "react-router-dom";
import Categories from "../CategoriesList/index"
import PulseLoader from "react-spinners/PulseLoader";

const Products = ({ categories, isLoading}) => {
    const navigate = useNavigate();

    return (
        <div className="productsContainer">

            <div className="productsDescription">
                <div style={{ "gridColumnStart": 2, "gridColumnEnd": 3 }}>
                    <h1>Vöruflokkar</h1>
                    <h2>Skoðaðu brot af því sem við bjóðum uppá</h2>
                </div>
            </div>
            {isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '3vh' }}>
                <PulseLoader color={"#21634f"} />
              </div>
      ) : (
        <div className="categoriesContainer"><Categories categories={categories} /></div>
      )}
            
        </div>
    )
}

export default Products;