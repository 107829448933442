import React from "react";
import "./style.css"
import NavLeft from "../NavLeft"
import NavMiddle from "../NavMiddle"
import NavRight from "../NavRight"

const NavBar = () => (

    <div className="nav-bar">
        <div className="nav-upper" />
        <div className="nav-lower">
            <NavLeft />
            <NavMiddle />
            <NavRight />
        </div>
    </div>

)

export default NavBar;