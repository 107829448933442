import React from "react";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenueItem from "@mui/material/MenuItem";

const SortBy = ({ products, setProducts, sort, setSort }) => {

    function sortBy(e) {
        let type = e.target.value;

        switch (type) {
            case 10: // sort by name descending
                setProducts(products.sort((a, b) => (a.name > b.name) ? 1 : -1));
                break;
            case 20: // sort by name ascending
                setProducts(products.sort((a, b) => (a.name < b.name) ? 1 : -1));
                break;
            case 30: // sort by date descending
                setProducts(products.sort((a, b) => (a.created < b.created) ? 1 : -1));
                break;
            case 40: // sort by date ascending
                setProducts(products.sort((a, b) => (a.created > b.created) ? 1 : -1));
            default:
                break;
        }
    }

    return (
        <FormControl className="drop-down"
        >
            <InputLabel htmlFor="outlined-age-native-simple">Raða eftir</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                value={sort}
                label="Raða eftir"
                onChange={event => {
                    setSort(event.target.value);
                    products ? sortBy(event) : console.log('no products'); // if products exist, sort them
                }}
                size="small"
                variant="outlined"
            >
                <MenueItem value={10}>Nafn (A - Ö)</MenueItem>
                <MenueItem value={20}>Nafn (Ö - A)</MenueItem>
                <MenueItem value={30}>Nýjast Efst </MenueItem>
                <MenueItem value={40}>Nýjast Neðst</MenueItem>

            </Select>
        </FormControl>
    )
}

export default SortBy;