import Product from '../Product/index'
import React from 'react';
import "./style.css"

const Products = ({ products }) => {

    if (!products) { return <div style={{ color: 'red', textAlign: 'center' }}>ATH! Engar vörur fundust.</div> }

    else {
        return <div className='productsListContainer'>
            {products.map(product => {
                return <Product product={product} />
            })}
        </div>;
    }

};

export default Products;