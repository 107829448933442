import React, { useState, useEffect } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { commerce } from './lib/commerce';
import NavBar from "./components/NavBar";
import MainContentHome from "./components/MainContentHome";
import Footer from "./components/Footer";
import Products from './components/Products';
import About from './components/About';
import Contact from './components/Contact';
import CategoryView from './components/CategoryView';
import SearchPage from './components/SearchPage';



const App = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCategories = () => {
    commerce.categories.list().then((categories) => {
      setCategories(categories.data);
      setIsLoading(false);
    }).catch((error) => {
      console.log('There was an error fetching the categories', error)
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCategories();

  }, []);

  return (
    <div style={{ width: "100vw" }}>
      <NavBar />
      <Routes>
        <Route path="/" element={<MainContentHome categories={categories} isLoading={isLoading} />}></Route>
        <Route path="/vorur" element={<Products categories={categories} isLoading={isLoading} />}></Route>
        <Route path="/umokkur" element={<About />}></Route>
        <Route path="/hafdusamband" element={<Contact />}></Route>
        <Route path="/category-view/:id" element={<CategoryView />}></Route>
        <Route path="/vorur/category-view/:id" element={<CategoryView />}></Route>
        <Route path="/voruleit" element={<SearchPage />}></Route>
      </Routes>
      <Footer />
    </div>

  );
}

export default App;
