import React, { useState, useEffect } from "react";
import "./style.css"
import { useSearchParams } from 'react-router-dom';
import Products from "../ProductsList/index";
import { commerce } from "../../lib/commerce";
import SortBy from "../SortBy";



const SearchPage = () => {
    const [products, setProducts] = useState([]);
    const [sort, setSort] = useState(10);
    const [searchParams] = useSearchParams();

    const fetchProducts = async () => { //fetch products with search params

        if (!searchParams.get("q")) {
            var { data } = [];
        } else {
            var { data } = await commerce.products.list({
                query: searchParams.get("q"),
            });
        }


        setProducts(data);

    };

    useEffect(() => {
        fetchProducts();
    }, [searchParams]);

    return (
        <div className="search-page-container">
            <div className="search-page-header">
                <h1>Vöruleit</h1>
                <SortBy products={products} setProducts={setProducts} sort={sort} setSort={setSort}></SortBy>
            </div>
            <div className="search-page-content">
                <Products products={products} />
            </div>
        </div>
    )
}
export default SearchPage;