import React from "react";
import "./style.css"

const Footer = () => (
        
    <div className="FooterContainer">
        <h1>BAPNÓR</h1>
        <h3>Allur réttur áskilinn</h3>
        <p>©Samasem</p>

    </div>

    )

export default Footer;