import React from "react";
import InstaLogo from "../../resources/instagram-32.png"
import FacebookLogo from "../../resources/facebook-3-32.png"
import "./style.css"


const NavRight = () => (
    <div className="navRight">
        <div className="socialMedia">
            <a href="https://www.facebook.com/blomaheildsalan">
                <img className="fb" src={FacebookLogo} alt="FaceBook Logo" />
            </a>
            <a href="https://www.instagram.com/blomaheildsalan/?hl=en">
                <img className="in" src={InstaLogo} alt="Instagram Logo" />
            </a>
        </div>
        <ul className="listNavRight">
            <li>Grensásvegur 22</li>
            <li>108 Reykjavík</li>
            <li> <a href="tel:5303500" style={{ "textDecoration": "none", "color": "inherit" }}>S: 5303500</a></li>
            <li> <a href="mailto: samasem@samasem.is" style={{ "textDecoration": "none", "color": "inherit" }}>samasem@samasem.is</a></li>
        </ul>

    </div>

)

export default NavRight;