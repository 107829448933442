import React from "react";
import "./style.css"
import { GoogleMap, useLoadScript, Marker} from "@react-google-maps/api";

function AboutMap() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });
    if (!isLoaded) return <div>Loading...</div>
    return <Map />;
}

function Map() {
    return <GoogleMap zoom={15} center={{ lat: 64.129142, lng: -21.876742 }} mapContainerClassName="aboutMap">
        <Marker label={'S'} position={{ lat: 64.129142, lng: -21.876742 }} />
    </GoogleMap>
}

const About = () => (

    <div className="aboutContainer">
        <h1>Um okkur!</h1>
        <div className="text">
            <h2>
                Samasem er blómaheildsala sem hefur verið starfrækt í meira en tvo áratugi.
                Frá upphafi hefur fyrirtækið einsett sér að auka vöruúrvalið á Íslandi með innflutningi
                blóma og plantna hvaðanæva að úr heiminum. Í rekstrarformi heildsölu hefur okkur tekist að bjóða
                fyrirtækjum og almenningi fersk afskorin blóm og árstíðabundnar pottaplöntur á viðráðanlegu verði,
                og þannig glatt landann með öllum þeim töfrum sem fylgja græneríi.
            </h2>
        </div>
        <div className="ssOutsideImage">

        </div>
        <div id="map" className="aboutMap">
            <AboutMap />
        </div>


    </div>

)

export default About;