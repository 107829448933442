import React, { useState, useEffect } from "react";
import LogoBig from "../../resources/LogoFullWhiteBg.webp";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
//import { slide as Menu } from 'react-burger-menu'
// import NavRight from "../NavRight";
import MobileSocialMedia from "../MobileSocialMedia";
import Searchbar from "../Searchbar";

const NavMiddle = () => {
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(true);
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  useEffect(() => {
    document.body.style.overflow = hidden ? "auto" : "hidden";
    document.body.ontouchmove = (e) => {
      if (!hidden) {
        e.preventDefault();
      }
    };
  }, [hidden]);

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]);

  function navList() {
    if (windowDimension.winWidth > 768) {
      return "flex";
    } else if (windowDimension.winWidth <= 768 && hidden) {
      return "none";
    } else {
      return "block";
    }
  }

  return (
    <div className="navMiddle">
      <img
        className="logoBig"
        src={LogoBig}
        alt="logoBig"
        onClick={() => {
          navigate("/");
        }}
      />
      <div
        className={hidden ? "burger-menu" : "burger-menu active"}
        onClick={() => setHidden(false)}
        typeof="button"
      >
        <div className={hidden ? "" : "mobile-backdrop"}></div>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      <ul style={{ display: navList() }} className="listNav">
        <div className="top-border" />
        <Link to="/">
          <li onClick={() => setHidden(true)}>Heim</li>
        </Link>
        <Link to="vorur">
          <li onClick={() => setHidden(true)}>Vörur</li>
        </Link>
        <Link to="umokkur">
          <li onClick={() => setHidden(true)}>Um okkur</li>
        </Link>
        <Link to="hafdusamband">
          <li onClick={() => setHidden(true)}>Hafðu samband</li>
        </Link>
        <span className="close" onClick={() => setHidden(true)}>
          <span className="bar"></span>
          <span className="bar"></span>
        </span>

        <MobileSocialMedia />
      </ul>
      <div className="searchbar-middle">
        <Searchbar />
      </div>
    </div>
  );
};

export default NavMiddle;
