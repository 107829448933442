import React from "react";
import Logo from "../../resources/LogoBlomNoBg.png"
import "./style.css"
import { Link } from "react-router-dom";
import Searchbar from "../Searchbar";

const NavLeft = () => {


    return (
        <div className="nav-left">
            <Link className="logoLink" to="/"><img className="logo" src={Logo} alt="logo" /></Link>
            <div className="searchbar"><Searchbar /></div>
        </div>)
}

export default NavLeft;