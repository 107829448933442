import React, { useState, useEffect } from "react";
import { commerce } from "../../lib/commerce";
import Products from "../ProductsList/index";
import "./style.css";
import SortBy from "../SortBy";
import PulseLoader from "react-spinners/PulseLoader";




const CategoryView = () => {
    const [category, setCategory] = useState({});
    const [products, setProducts] = useState([]);
    const [sort, setSort] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const fetchCategory = async (id) => {
        setIsLoading(true);
        const response = await commerce.categories.retrieve(id);
        const { name, description } = response;
        setCategory({
            id,
            name,
            description,
        });
    };

    const fetchProductsByCategory = async (id) => {
        const products = await commerce.products.list({
            category_id: id,
            limit: 200,
        })
        setProducts(products.data)
        setIsLoading(false);
    };


    useEffect(() => {
        const id = window.location.pathname.split("/");
        fetchCategory(id[id.length - 1]);
        fetchProductsByCategory(id[id.length - 1]);
    }, []);

    return (
        <div className="categoryInfoContainer">

            <div className="catergoryInfoSubContainer">
                <h1 className="categoryInfoName">{category.name}</h1>

                <SortBy products={products} setProducts={setProducts} sort={sort} setSort={setSort}></SortBy>

            </div>
            <div className="categoryInfoDescription">{category.description}</div>
            {isLoading ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20vh' }}>
                <PulseLoader color={"#21634f"} />
              </div>
      ) : (
        <div className="categoryProductsContainer"><Products products={products}></Products></div>
      )}
            
        </div>
    );
};

export default CategoryView;